import React from 'react';
import './Footer.sass'
import logo from './../../../assests/img/logo.svg'


const Footer = () => {
    return (
        <footer className='footer-wrapper'>
            <div style={{display: 'flex',  flexDirection: 'row',  alignItems: 'center', gap: '0.5em'}} className='footer__logo-container'>
                <a href="https://factsandmedia.ru/" target="_blank" >
                    <img src={logo} alt="логотип факты и медиа"/>
                </a>

                <ul style={{display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
                    <li>ООО «Факты и медиа»</li>

                    <li>ОГРН 1236100000575</li>
                </ul>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
               <span>Связаться с нами:</span>

                <ul style={{display: 'flex',  flexDirection: 'column', gap: '0.5em'}}>
                    <li><a href='mailto:factsandmedia@yandex.ru'></a>factsandmedia@yandex.ru</li>
                    <li><a style={{color: 'white'}} href="https://сервис.интернетбезфейков.рф/policy/2023-%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf">
                        Пользовательское соглашение</a></li>
                </ul>
            </div>

            <div style={{width: '20%'}}>
                <p>
                    Сервис разработан при грантовой поддержке Фонда содействия инновациям</p>
            </div>

        </footer>
    );
};

export default Footer;