import React from 'react';
import './modal.sass';
import {MdClose} from "react-icons/md";

const Modal = ({ show, close, children }) => {
    return (
        <div className={show ? 'modal display-block' : 'modal display-none'}>
            <section className='modal-main'>
                {children}
                <button className='modal-btnClose' onClick={close}><MdClose  size={20}/></button>
            </section>
        </div>
    );
};

export default Modal;
