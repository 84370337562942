import React, {useState, useEffect} from 'react';

import './history.sass'
import Modal from "../UI/modal/Modal";
import {BsQuestionCircle} from "react-icons/bs";
import {Tooltip} from "react-tooltip";

const History = () => {
    const [checks, setChecks] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeCheck, setActiveCheck] = useState(null);

    useEffect(() => {
        fetch('api/check/history', {
            method: 'GET', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const transformedData = data.map(item => {
                    const date = new Date(item.checkTime);
                    const formattedTime = date.toLocaleString();
                    return {
                        time: formattedTime,
                        type: item.mediaRating !== null ? 'Ссылка' : 'Текст',
                        result: item.totalRating > 70 ? 'Правда' : 'Фейк',
                        truthProbability: item.truthProbability === null ? '0' : item.truthProbability,
                        newsAgendaAccuracy: item.newsAgendaAccuracy,
                        facts: item.facts,
                        mediaRating: item.mediaRating,
                        totalRating: item.totalRating,
                        urlHistory: item.url,
                        textHistory: item.text
                    };
                });
                setChecks(transformedData.reverse());
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }, []);

    const handleMoreDetails = (check) => {
        setActiveCheck(check);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveCheck(null);
    };

    function limitToSentences(text, limit) {
        const sentences = text.match(/[^.!?]+[.!?]/g) || [];

        if (sentences.length === 0) {
            return text;
        }

        return sentences.slice(0, limit).join(' ');
    }

    return (<div>

        <div className="history-content__wrapper">
            <h3>История проверок </h3>
            <div className="history-table__wrapper">
                <table className="history-table">
                    <thead>
                    <tr>
                        <th className="history-table__head">Время запроса</th>
                        <th className="history-table__head">Тип запроса</th>
                        <th className="history-table__head">Результат проверки</th>
                        <th className="history-table__head"></th>

                    </tr>
                    </thead>
                    <tbody>
                    {checks.map((check, index) => (<tr key={index}>
                        <td className='history-table__cell'>{check.time}</td>
                        <td className='history-table__cell'>{check.type}</td>
                        <td className='history-table__cell'>{check.result}</td>
                        <th className='history-table__cell--btn'>
                            <button className='history-table__btn' onClick={() => handleMoreDetails(check)}>Подробнее
                            </button>
                        </th>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </div>

        {isModalOpen && (
            <Modal show={isModalOpen} close={handleCloseModal}>
                <h4 style={{fontWeight: 'bold', fontSize: '1.35em', textAlign: 'center'}}>Детали проверки</h4>
                <p style={{marginTop: '1em'}}>
                    {activeCheck.urlHistory !== null ? <p style={{wordWrap: 'break-word'}}> <span style={{fontWeight: '550', color: "#03178C", fontSize: '1.2em'}}>Ссылка на новость: </span>  {activeCheck.urlHistory}</p>  : <p></p>}
                    {(activeCheck.textHistory && activeCheck.textHistory.trim().length > 0) ?
                        <p style={{marginTop: '1em', wordWrap: 'break-word'}}>
                            <span style={{fontWeight: '550', color: "#03178C", fontSize: '1.2em'}}>Текст новости: </span>
                            {limitToSentences(activeCheck.textHistory, 5)}
                        </p>
                        : null}


                </p>

                <div style={{display: 'flex', gap: '0.5em', flexDirection: 'column', margin: '1.3em 0 1em 0'}}>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        color: "#03178C"
                    }}>Итоговый рейтинг:</span>

                    <div style={{
                        height: '20px',
                        position: 'relative',
                        width: '100%',
                        backgroundColor: '#b3b3b3',
                        borderRadius: '5px'
                    }}>
                        <div style={{
                            height: '100%',
                            width: `${activeCheck.totalRating}%`,
                            backgroundColor: activeCheck.totalRating <= 25 ? '#FF0000' : activeCheck.totalRating <= 75 ? '#FFA500' : '#0C9C0C',
                            borderRadius: '5px',
                            transition: 'width 0.5s',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end"
                        }}>
                            <span style={{
                                float: 'right',
                                position: 'absolute',
                                left: '5px',
                                top: '2px',
                                color: 'white',
                                fontWeight: 'bold'
                            }}>{activeCheck.mediaRating === -100 || activeCheck.mediaRating === -50 ? '0' : activeCheck.totalRating}%</span>
                        </div>
                    </div>

                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
                    {activeCheck.type === 'Ссылка' && (
                        <>
                            <p style={{
                                display: "flex",
                                alignItems: "center",
                                gap: '0.3em'
                            }}>Рейтинг источника новости: <span style={{fontWeight: 'bold', color: "#03178C", display: 'flex',
                                alignItems: 'center',
                                width: "fit-content",
                                gap: '0.3em'}}>
                    {activeCheck.mediaRating === -100 || activeCheck.mediaRating === -50 ? '0' : activeCheck.mediaRating}%
                                <a id='check-rating'
                                   style={{width: 'fit-content'}}>
                                <BsQuestionCircle color='gray' size={20}/>
                            </a>
                </span>
                            </p>
                            <Tooltip style={{maxWidth: '250px'}} anchorSelect='#check-rating'
                                     place='right-end'
                                     content='Оценка по данному критерию отражает уровень надежности источника,
                                  который выпустил проверяемую новость. &#10; Чем выше оценка,
                                   тем больше вероятность, что новость является правдивой.'/>
                        </>
                    )}

                    <p style={{
                        display: "flex",
                        alignItems: "center",
                        gap: '0.3em'
                    }}>
                        Схожесть с паттернами правдивых новостей: <span style={{
                        fontWeight: 'bold', color: "#03178C", display: 'flex',
                        alignItems: 'center',
                        width: "fit-content",
                        gap: '0.3em'
                    }}>
            {activeCheck.mediaRating === -100 || activeCheck.mediaRating === -50 ? '0' : activeCheck.truthProbability}%
                        <a id='check-truth' style={{width: 'fit-content'}}><BsQuestionCircle color='gray'
                                                                                             size={20}/></a>
        </span></p>
                    <Tooltip style={{maxWidth: '250px'}} anchorSelect='#check-truth'
                             place='right-end'
                             content='Оценка по данному критерию отражает схожесть текста новости с паттернами, которые характерны для правдивых новостей. Чем выше оценка, тем больше вероятность, что новость не является фейк-ньюс'
                    />

                    <p style={{
                        display: "flex",
                        alignItems: "center",
                        gap: '0.3em'
                    }}>Совпадение с новостной повесткой дня (НПД): <span style={{
                        fontWeight: 'bold', color: "#03178C", display: 'flex',
                        alignItems: 'center',
                        width: "fit-content",
                        gap: '0.3em'
                    }}>
            {activeCheck.mediaRating === -100 || activeCheck.mediaRating === -50 ? '0' : activeCheck.newsAgendaAccuracy}%
                        <a id='check-npd' style={{width: 'fit-content'}}><BsQuestionCircle
                            color='gray' size={20}/></a>
        </span></p>
                    <Tooltip style={{maxWidth: '250px'}} anchorSelect='#check-npd'
                             place='right-end'
                             content='Новостная повестка дня – основные темы, которые обсуждаются в сми в течение дня.
                             Чем выше процент совпадения новости с НПД, тем больше вероятность,
                              что новость не является фейк-ньюс'/>


                    <p>Время проверки: {activeCheck.time}</p>


                    {activeCheck.mediaRating === -100 &&
                        <p style={{fontWeight: 'bold', color: 'red'}}>Система не проверяет сатирические новости</p>}
                    {activeCheck.mediaRating === -50 &&
                        <p style={{fontWeight: 'bold', color: 'red'}}>Система считает новости из социальных сетей
                            недостоверными</p>}

                </div>

            </Modal>
        )}

    </div>);
};

export default History;
