import React, {useState} from 'react';
import {MdEdit, MdDone, MdClose} from 'react-icons/md';
import './editableRow.sass'

const EditableRow = ({fieldName, initValue, onSave, showEditButton, field, secondValue = true}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(initValue);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditValue(initValue);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        onSave(fieldName, editValue);

        // Запрос к серверу
        fetch('api/users/profile', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                [field]: editValue,
                [secondValue.field]: secondValue.value
            })
        })
            .then(response => response.json())
            .then(data => {

                console.log(data);
            })
            .catch(error => {

                console.error('Error:', error);
            });
    };


    return (
        <tr className="ac-tr">
            {isEditing ? (

                <>

                    <td colSpan={2}>
                        <input className="ac__td-input" value={editValue} onChange={e => setEditValue(e.target.value)}/>
                    </td>
                    <td>
                        <div className="ac__row-info__btn-wrapper">
                            <button className="ac__row-info__btn"  style={{borderRight: '1px solid #03178C'}} onClick={handleSaveClick}><MdDone/></button>
                            <button className="ac__row-info__btn" onClick={handleCancelClick}><MdClose/></button>
                        </div>

                    </td>
                </>


            ) : (
                <>
                    <td className="ac__td-heading">{fieldName}:</td>
                    <td className="ac__row-info">
                        {initValue}

                    </td>
                    <td>
                        {showEditButton && (
                            <div className="ac__row-info__btn-wrapper">
                                <button className="ac__row-info__btn" onClick={handleEditClick}>
                                    <MdEdit/>
                                </button>
                            </div>
                        )}
                    </td>
                </>
            )}
        </tr>
    );
};

export default EditableRow;
