import React from 'react';
import './header.sass'
import logo from './../../../assests/img/logo.svg'
import {Link} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {MdExitToApp} from "react-icons/md";

const Header = () => {
    const navigate = useNavigate();
    function logout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userRole');
        navigate('/');
    }

    return (<>
            <header className='header-wrapper'>
                <Link to='/check'><img src={logo} alt="логотип ФАКТЫ И МЕДИА" className="header__logo"/></Link>
                <div className='header__button-wrapper'>
                    <div className='column'>
                        <Link to='/check'>
                            <button className='header__btnWhite'>Проверка</button>
                        </Link>
                        <Link to='/history'>
                            <button className='header__btnWhite'>История</button>
                        </Link>
                    </div>
                        <div className='column'>
                            <Link to='/account'>
                                <button className='header__btnWhite'>Аккаунт</button>
                            </Link>
                            <button className='header__logout' onClick={logout} style={{
                                border: "none",
                                backgroundColor: 'inherit',
                                color: 'white',
                                fontSize: "1em",
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}><span style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>Выйти <MdExitToApp size={20}/> </span></button>
                        </div>

                </div>

            </header>
        </>

    );
};

export default Header;