import React from 'react';
import './headerAdmin.sass'
import logo from './../../../assests/img/logo.svg'
import {Link} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {MdExitToApp} from "react-icons/md";

const HeaderAdmin = () => {
    const navigate = useNavigate();
    function logout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userRole');
        navigate('/');
    }

    return (<>
            <header className='header-admin-wrapper'>
                <Link to='/check'><img src={logo} alt="логотип ФАКТЫ И МЕДИА" className="header-admin__logo"/></Link>
                <div className='header-admin__button-wrapper'>
                    <Link to='/admin/feedback'>
                        <button className='header-admin__btnWhite'>Отзывы</button>
                    </Link>
                    <Link to='/admin/users'>
                        <button className='header-admin__btnWhite'>Пользователи</button>
                    </Link>

                    <button className='header-admin__logout' onClick={logout} style={{
                        border: "none",
                        backgroundColor: 'inherit',
                        color: 'white',
                        fontSize: "1em",
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}><span style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>Выйти <MdExitToApp size={20}/> </span></button>
                </div>

            </header>
        </>

    );
};

export default HeaderAdmin;