import React, {useState} from 'react';
import './applicationForm.sass'

const ApplicationForm = () => {
    const [fio, setFio] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);

    const isButtonDisabled = () => {
        return !(fio && email && phone && organization && isChecked && isValidEmail(email) && isValidPhone(phone));
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);


        if (!value) {
            setError(prevErrors => prevErrors.filter(err => err !== "Некорректный E-mail"));
            return;
        }

        if (!isValidEmail(value) && !error.includes("Некорректный E-mail")) {
            setError(prevErrors => [...prevErrors, "Некорректный E-mail"]);
        } else if (isValidEmail(value)) {
            setError(prevErrors => prevErrors.filter(err => err !== "Некорректный E-mail"));
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);

        if (!value) {
            setError(prevErrors => prevErrors.filter(err => err !== "Некорректный номер телефона"));
            return;
        }

        if (!isValidPhone(value) && !error.includes("Некорректный номер телефона")) {
            setError(prevErrors => [...prevErrors, "Некорректный номер телефона"]);
        } else if (isValidPhone(value)) {
            setError(prevErrors => prevErrors.filter(err => err !== "Некорректный номер телефона"));
        }
    };


    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const isValidPhone = (phone) => {
        const pattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
        return pattern.test(phone);
    };



    const handleApplication = async (event) => {
        event.preventDefault();

        setError([]);
        setSuccessMessage([]);


        const response = await fetch('api/form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fio: fio,
                email: email,
                phone: phone,
                organization: organization
            })
        });

        if (response.ok) {
            setError([]);
            setFio('');
            setEmail('');
            setPhone('');
            setOrganization('');
            setIsChecked(false);
            setSuccessMessage("Заявка успешно отправлена!");

        } else {
            setError(['Ошибка при отправке']);
        }
    };

    return (
        <div className='applicationForm-wrapper'>
            <form className='applicationForm-content' onSubmit={handleApplication}>
                <h1 className='applicationForm-heading'>Подать заявку</h1>
                    {error.map((errMsg, index) => (
                        <p key={index} style={{ color: 'red', fontSize: '0.9em' }}>{errMsg}</p>
                    ))}

                <input className="applicationForm__input" placeholder='ФИО' value={fio}
                       onChange={(e) => setFio(e.target.value)}/>
                <input className="applicationForm__input" placeholder='E-mail'  value={email}
                       onChange={handleEmailChange}/>
                <input className="applicationForm__input" placeholder='Телефон' value={phone}
                       onChange={handlePhoneChange}/>
                <input className="applicationForm__input" placeholder='Название организации'
                       value={organization} onChange={(e) => setOrganization(e.target.value)}/>
                <div style={{display: 'flex', flexDirection: 'row', gap: '0.5em'}}>
                    <input type="checkbox" style={{cursor: "pointer"}}
                           checked={isChecked}
                           onChange={(e) => setIsChecked(e.target.checked)}/>
                    <span style={{textAlign: 'start'}}>Согласен с <a style={{color: 'black'}}
                                                                     href="https://сервис.интернетбезфейков.рф/policy/2023-%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf">
                        правилами конфиденциальности</a></span>
                </div>

                {successMessage && <p style={{color: 'green', fontSize: '1em'}}>{successMessage}</p>}
                <button className='applicationForm-btnPurple'
                        disabled={isButtonDisabled()} type="submit">Отправить заявку
                </button>
            </form>
        </div>
    );
};

export default ApplicationForm;
