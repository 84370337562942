import React, { useState, useEffect } from 'react';
import './account.sass'

import EditableRow from "../UI/editableRow/editableRow";
import { accountTypeToText } from '../../utils/constants/accountTypeToText';


const Account = () => {
    const [fio, setFio] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [subscribe, setSubscribe] = useState(null);
    const [totalChecks, setTotalChecks] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        fetch('api/users/profile', {
            method: 'GET', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setFio(data.fio)
                setOrganization(data.organization)
                setSubscribe(data.subscribe)
                setTotalChecks(data.totalChecks)
                setRole(data.role)
            })
            .catch(error => console.log(error));
    }, []);


    const handleSave = (fieldName, newValue) => {
        switch (fieldName) {
            case "ФИО":
                setFio(newValue);
                break;
            case "Название организации":
                setOrganization(newValue);
                break;
            case "Статус подписки":
                setSubscribe(newValue);
                break;
            case "Количество проверок":
                setTotalChecks(newValue);
                break;
            default:
                break;
        }

    };

    return (<div>
        <div className='ac-wrapper'>
            <h3>Личный кабинет</h3>

            <table className='ac__table-wrapper'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="ac-tr">
                        <td className="ac__td-heading">Тип аккаунта:</td>
                        <td className="ac__row-info">
                            {accountTypeToText[role]}
                        </td>
                        <td></td>
                    </tr>

                    {role === "User" && <EditableRow fieldName="ФИО" field={'fio'} secondValue={{ field: 'organization', value: organization }} initValue={fio} onSave={handleSave} showEditButton={true} />}
                    {role === "User" && <EditableRow fieldName="Название организации" field={'organization'} secondValue={{ field: 'fio', value: fio }} initValue={organization} onSave={handleSave} showEditButton={true} />}
                    {role === "User" && <EditableRow fieldName="Статус подписки" initValue={subscribe === "Active" ? "Активна" : "Не активна"} onSave={handleSave} showEditButton={false} />}

                    <tr className="ac-tr">
                        <td className="ac__td-heading">Количество проверок:</td>
                        <td className="ac__row-info">
                            {totalChecks}
                        </td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

        </div>
    </div>);
};

export default Account;