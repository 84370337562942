import React, { useEffect, useState } from 'react';
import './createUser.sass'
import HeaderAdmin from "../../UI/headerAdmin/headerAdmin";
import Modal from "../../UI/modal/Modal";
import { accountTypeToText } from '../../../utils/constants/accountTypeToText';


const CreateUser = () => {
    const [modalShow, setModalShow] = useState(false);
    const [fio, setFio] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState('');
    const [userCreated, setUserCreated] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [userType, setUserType] = useState('user');
    const [currentUserRole, setCurrentUserRole] = useState(null);

    useEffect(() => {
        const role = sessionStorage.getItem('userRole');
        setCurrentUserRole(role);
    }, []);


    useEffect(() => {

        if (userType === 'admin') {
            setIsFormValid(username !== '' && password !== '');
            clearUserFields();
        } else if (userType === 'user'){
            setIsFormValid(organization !== '' && password !== '' && username !== '' && fio !== '');
        }else if (userType === 'test'){
            setIsFormValid(username !== '' && password !== '');
            clearUserFields();
        }
    }, [password, organization, username, fio, userType]);


    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (userType === 'user') {
            handleRegisterUser(event);
        } else if (userType === 'admin') {
            handleRegisterAdmin(event)
        } else if (userType === 'test') {
            handleRegisterTest(event)
        }
    };

    const clearFormFields = () => {
        setUsername('');
        setPassword('');

        setError(null);
    };

    const clearUserFields = () => {
        setFio('');
        setOrganization('');
    };



    const handleRegisterUser = async (event) => {
        event.preventDefault();
        fetch('/api/users', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                fio: fio,
                organization: organization,
                login: username,
                password: password

            })
        }).then(response => {
            if (response.ok) {
                setUserCreated(true);
                setError(null);
                clearFormFields();
            } else {
                throw new Error();
            }
        })
            .catch(error => {
                setUserCreated(false);
                setError(<p style={{ fontSize: '0.9em', textAlign: 'start' }}>Пользователь с таким логином уже
                    зарегистрирован.</p>)
            });
    };

    const handleRegisterAdmin = async (event) => {
        event.preventDefault();
        fetch('/api/users/admin', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                login: username,
                password: password

            })
        }).then(response => {
            if (response.ok) {
                setUserCreated(true);
                setError(null);
                clearFormFields();
            } else {
                throw new Error();
            }
        })
            .catch(error => {
                setUserCreated(false);
                setError(<p style={{ fontSize: '0.9em', textAlign: 'start' }}>Администратор с таким логином уже
                    зарегистрирован.</p>)
            });
    };

    const handleRegisterTest = async (event) => {
        event.preventDefault();
        fetch('/api/users/testing', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                login: username,
                password: password

            })
        }).then(response => {
            if (response.ok) {
                setUserCreated(true);
                setError(null);
                clearFormFields();
            } else {
                throw new Error();
            }
        })
            .catch(error => {
                setUserCreated(false);
                setError(<p style={{ fontSize: '0.9em', textAlign: 'start' }}>Пользователь с таким логином уже
                    зарегистрирован.</p>)
            });
    };


    const handleCreateClick = () => {
        setModalShow(true);
    };

    const handleModalClose = () => {
        setModalShow(false);
        clearFormFields();
        clearUserFields();
        setUserCreated(false);
    };


    useEffect(() => {
        fetch('/api/users', {
            method: 'GET', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.error("Ошибка при получении пользователей: ", error);
            });
    }, []);


    return (
        <div>
            <HeaderAdmin />
            <div className='create-user-wrapper'>
                <div className='create-user-content'>
                    <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                        <h1 style={{ color: '#03178C', textAlign: 'center' }}>
                            Список пользователей
                        </h1>
                        <button onClick={handleCreateClick} className='create-user__btn'
                            disabled={currentUserRole !== 'Admin'}> Создать
                        </button>
                    </div>


                    {users.map(user => (
                        <div key={user.id} className='user-wrapper'>
                            <ul>
                                {user.role === 'User' && (
                                    <li><span style={{ fontWeight: 'bold', color: '#03178C' }}> ФИО: </span>{user.fio}</li>)
                                }
                                {user.role === 'User' && (<li><span style={{
                                    fontWeight: 'bold',
                                    color: '#03178C'
                                }}> Организация: </span> {user.organization}</li>)
                                }

                                {user.role === 'User' && <li><span
                                    style={{ fontWeight: 'bold', color: '#03178C' }}> Подписка: </span>{user.subscribe}
                                </li>}
                                <li><span style={{
                                    fontWeight: 'bold',
                                    color: '#03178C'
                                }}> Выполнено проверок:</span> {user.totalChecks}</li>
                                <li><span style={{ fontWeight: 'bold', color: '#03178C' }}> Роль:</span> {accountTypeToText[user.role]}</li>
                            </ul>
                        </div>
                    ))}

                </div>

            </div>
            <Modal show={modalShow} close={handleModalClose}>
                <h2 style={{
                    color: '#03178C',
                    textAlign: 'center'
                }}> {userType === 'user' ? 'Создать пользователя' : 'Создать администратора'}</h2>


                {
                    userCreated &&
                    <p style={{ marginTop: '1em', fontWeight: 'bold', textAlign: 'start' }}>
                        {userType === 'admin' ? 'Администратор успешно создан' : 'Пользователь успешно создан!'} </p>
                }

                <form className='registerForm-content' onSubmit={handleFormSubmit}>

                    {error && <p className="error">{error}</p>}

                    <select className='registerForm__select' value={userType}
                        onChange={(e) => setUserType(e.target.value)}>
                        <option value="user">Пользователь</option>
                        <option value="admin">Админ</option>
                        <option value="test">Тестовый аккаунт</option>
                    </select>

                    {userType === 'user' && <>
                        <input className="registerForm__input" placeholder='ФИО' value={fio}
                            onChange={(e) => setFio(e.target.value)} />
                        <input className="registerForm__input" placeholder='Организация'
                            value={organization} onChange={(e) => setOrganization(e.target.value)} />
                    </>}
                    <input className="registerForm__input" placeholder='Логин' value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                    <input className="registerForm__input" placeholder='Пароль' type="password" value={password}
                        onChange={(e) => setPassword(e.target.value)} />

                    <button className='registerForm-btnPurple' type="submit" disabled={!isFormValid}>Зарегистрировать
                    </button>
                </form>
            </Modal>
        </div>
    );
};

export default CreateUser;