import React, {useState} from 'react';
import logo from './../../../assests/img/logo.svg'
import './adminLogin.sass'
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../AuthContext";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";

const AdminLogin = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const { setUser } = useAuth();

    const isButtonDisabled = !login || !password;

    const handleLogin = async (event) => {
        event.preventDefault();
        const response = await fetch('api/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: login,
                password: password
            })
        });

        setPassword('');

        if (response.ok) {
            const data = await response.json();
            sessionStorage.setItem('token', data.token);
            sessionStorage.setItem('userRole', data.userRole);
            setUser(data);

            if (data.userRole === 'Admin') {
                navigate('/admin/feedback')
            } else {
                navigate('/check');
            }

        } else {
            setError('Ошибка при входе');
        }
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: "center",
                height: '100vh',
                color: 'white'
            }}>
                <form onSubmit={handleLogin} style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '30%',
                    gap: '1em',
                    boxSizing: 'border-box',
                    backgroundColor: '#03178C',
                    padding: "1em"
                }}>
                    <img src={logo} alt="логотип факты и медиа" width="30%"/>
                    <h1 style={{
                        color: 'white',
                        fontSize: '1.55em'
                    }}>Войти как администратор</h1>
                    <p style={{fontSize: '1.25em'}}>
                        {error}
                    </p>

                    <input className='admin-input' type="text" placeholder='Логин' value={login}
                           onChange={(e) => setLogin(e.target.value)}/>
                    <div style={{position: 'relative', display: 'inline-block', width: '100%'}}>
                        <input className='admin-input' type={showPassword ? "text" : "password"} placeholder='Пароль' value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                        <button type="button" className="togglePasswordAdmin-btn" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <MdVisibilityOff size={22}/>:  <MdVisibility size={22}/>}
                        </button>
                    </div>

                    <button
                        className='admin__btn'
                        disabled={isButtonDisabled}
                        style={{
                            backgroundColor: 'white',
                            color: '#03178C',
                            cursor: 'pointer',
                            border: 'none',
                            padding: '0.7em 1.1em',
                            fontWeight: 'bold',
                            fontSize: '1em',
                            width: '100%'
                        }}>Войти
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;