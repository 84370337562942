import React, { useState } from 'react';
import './headerMain.sass';
import logo from '../../../assests/img/logo.svg';
import logo_w from '../../../assests/img/logo_w.svg';
import LoginForm from '../loginForm/LoginForm';
import Modal from '../modal/Modal';

const HeaderMain = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleLoginButtonClick = () => {
        setModalContent(<LoginForm close={handleModalClose} />);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setModalContent(null);
    };

    return (
        <header className='headerMain-wrapper'>
            <div className="headerMain-content">
                <img src={logo} alt="логотип ФАКТЫ И МЕДИА" className='headerMain-logo' />
                <img src={logo_w} alt="логотип ФСИ" className='headerMain-logo' />
                {/*<div className='headerMain__button-wrapper'>*/}
                {/*    /!*<button className='headerMain__btnWhite' onClick={handleLoginButtonClick}>Войти</button>*!/*/}
                {/*    <Modal show={isModalVisible} close={handleModalClose}>*/}
                {/*        {modalContent}*/}
                {/*    </Modal>*/}
                {/*</div>*/}
            </div>
        </header>
    );
};

export default HeaderMain;
