import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Main from "./components/main/Main";
import Account from "./components/account/Account";
import Check from "./components/check/Check";
import {Helmet} from "react-helmet";
import History from "./components/history/history";

import Header from "./components/UI/header/Header";
import Guard from "./Guard";
import Footer from "./components/UI/footer/Footer";
import Feedback from "./components/Admin/feedback/feedback";
import CreateUser from "./components/Admin/createUser/createUser";
import AdminLogin from "./components/Admin/adminLogin/adminLogin";
import {AuthProvider} from "./AuthContext";

function App() {
    return (<>
        <Helmet>
            <title>Интернетбезфейков</title>
        </Helmet>

        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/admin" element={<AdminLogin/>}/>
                    <Route path="/admin/users" element={<Guard><CreateUser/></Guard>}/>
                    <Route path="/admin/feedback" element={<Guard><Feedback/></Guard>}/>
                    <Route path="/account" element={<div className='container-app'>
                        <Guard>
                            <div className='content-app'>
                                <Header/>
                                <Account/>
                            </div>
                            <div className='footer-app'>
                                <Footer/>
                            </div>

                        </Guard>
                    </div>}/>
                    <Route path="/check" element={<div className='container-app'>
                        <Guard>
                            <div className='content-app'>
                                <Header/>
                                <Check/>
                            </div>
                            <div className='footer-app'>
                                <Footer/>
                            </div>
                        </Guard>
                    </div>}/>
                    <Route path="/history" element={<div className='container-app'>
                        <Guard>
                            <div className='content-app'>
                                <Header/>
                                <History/>
                            </div>
                            <div className='footer-app'>
                                <Footer/>
                            </div>

                        </Guard>
                    </div>}/>

                </Routes>
            </BrowserRouter>
        </AuthProvider>

    </>);
}

export default App;
