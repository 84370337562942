import React, {useState} from 'react';
import './main.sass'
import HeaderMain from "../UI/headerMain/HeaderMain";
import Modal from "../UI/modal/Modal";
import ApplicationForm from "../UI/applicationForm/applicationForm";



const Main = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleApplicationClick = () => {
        setModalContent(<ApplicationForm/>)
        setModalShow(true);
    };

    return (
        <div className='main-wrapper'>
            <HeaderMain/>
            <div className='main-content-wrapper' >
                <h1 className='main-heading'>Интернет-сервис
                    проверки недостоверной информации
                    «(Не) верю»</h1>
                <div className="main__btn-wrapper">
                    <button className='main-btnPink' onClick={handleApplicationClick}> Хочу попробовать! </button>
                </div>
                <ul className="main__list-wrapper">
                    <li>
                        <h2>Автоматизированная проверка</h2>
                        <p>
                            «(Не) верю» предлагает автоматизированный подход к проверке информации в интернете. Это
                            отличает
                            наш сервис от аналогов, которые требуют активного участия пользователей в маркировке
                            фейковых
                            новостей.
                        </p>
                    </li>
                    <li>
                        <h2>Большие объемы текстов и источников</h2>
                        <p>Наш сервис способен обрабатывать большие объемы текстов и источников информации. Это
                            означает, что он подходит для владельцев медиа, журналистов, представителей власти и всех,
                            кто ежедневно сталкивается с необходимостью проверки достоверности данных.</p>
                    </li>
                    <li>
                        <h2>Без найма фактчекеров </h2>
                        <p>Использование «(Не) верю» позволяет избежать найма штата фактчекеров. Наш сервис
                            предоставляет эффективный инструмент для проверки информации, не требуя дополнительных
                            человеческих ресурсов.</p>
                    </li>
                    <li>
                        <h2>Качественный результат</h2>
                        <p>«(Не) верю» обеспечивает качественную проверку информации в интернете. Мы постоянно
                            совершенствуем наш сервис, чтобы предоставлять надежные результаты, на которые можно
                            положиться при принятии важных политических, экономических и бизнес-решений.</p>
                    </li>
                </ul>



            </div>
            <Modal show={modalShow} close={() => setModalShow(false)}>
                { modalShow && <ApplicationForm/> }
            </Modal>

        </div>
    );
};

export default Main;