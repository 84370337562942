import React, {useEffect, useState} from 'react';
import './feedback.sass'
import HeaderAdmin from "../../UI/headerAdmin/headerAdmin";

const Feedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);

    useEffect( () => {
        fetch('/api/users/feedback', {
            method: 'GET', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(response => response.json())
            .then(data => {
                data.sort((a, b) => new Date(b.feedbackTime) - new Date(a.feedbackTime));
                setFeedbacks(data);
            }).catch(error => {
            console.error("Ошибка при получении отзывов: ", error);
        });
    }, [] )

    return (
        <div>
            <HeaderAdmin/>
             <div className='feedback-wrapper'>
                <div className='feedback-content'>
                    <h1 style={{color: '#03178C', textAlign: 'center'}}>Отзывы</h1>
                    {feedbacks.map(feedback => (
                        <div  className='feedback-user-wrapper'>
                            <ul>
                                <li> <span style={{fontWeight: 'bold', color: '#03178C'}}> ФИО:</span> <span></span> {feedback.fio} </li>
                                <li> <span style={{fontWeight: 'bold', color: '#03178C'}}> Согласен ли пользователь с проверкой?:</span> {feedback.rating}  </li>

                                <li> <span style={{fontWeight: 'bold', color: '#03178C'}}> Комментарий:</span> {feedback.comment === null ? 'Пользователь не оставил комментарий' : feedback.comment } </li>
                                <li> <span style={{fontWeight: 'bold', color: '#03178C'}}> Время отправки отзыва:</span> {new Date (feedback.feedbackTime).toLocaleString()}  </li>
                            </ul>
                        </div>))}

                </div>
             </div>

        </div>
    );
};

export default Feedback;