import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const Guard = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = sessionStorage.getItem('userRole');

    useEffect(() => {
        if (!sessionStorage.getItem('token') && location.pathname !== "/") {
            navigate("/");
        } else if (location.pathname.startsWith('/admin') && userRole !== 'Admin') {
            navigate("/");
        }
    }, [navigate, location, userRole]);

    return children;
};

export default Guard;