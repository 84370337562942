import React, { useState } from 'react';
import './Check.sass'
import Textarea from "../UI/textarea/Textarea";
import Input from "../UI/Input/Input";
import { Tooltip } from 'react-tooltip';
import { BsQuestionCircle } from "react-icons/bs";
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

const Check = () => {
    const [selection, setSelection] = useState('text');
    const [inputValue, setInputValue] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [api, setApi] = useState('text')
    const [agreeReport, setAgreeReport] = useState(null);
    const [disagreeFeedback, setDisagreeFeedback] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [feedbackId, setFeedbackId] = useState(null);
    const [isServerError, setIsServerError] = useState(false);
    const [userRating, setUserRating] = useState(null);
    const [mediaId, setMediaId] = useState(null);


    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
        setInputValue('');
        setResult(null);
        setShowThankYou(false);
        setIsFeedbackSent(false);
        setAgreeReport(null);
        setError('');
        setUserRating(null)
        if (event.target.value === 'text') {
            setApi('text')
        } else {
            setApi('url')
        }
    }

    const sendFeedback = async (rating, comment) => {
        console.log(comment)
        if (userRating && mediaId !== 0) {
            await fetch(`api/check/rating`, {
                method: 'POST', headers: {
                    "Content-Type": "application/json",
                    accept: 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }, body: JSON.stringify({
                    userRating, mediaId
                })
            })
        }
        await fetch(`api/check/${feedbackId}/feedback`, {

            method: 'POST', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }, body: JSON.stringify({
                rating, comment
            })
        }).then(() => setIsFeedbackSent(true))
    }


    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }


    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (selection === 'url') {

            if (value.trim() === '') {
                setIsButtonDisabled(true)
                setError('Пожалуйста, введите действительный URL');
            } else if (!isValidUrl(value)) {
                setIsButtonDisabled(true)
                setError('Пожалуйста, введите действительный URL');
            } else {
                setIsButtonDisabled(false)
                setError('');
            }
        } else {
            if (value.trim() === '') {
                setIsButtonDisabled(true)
                setError('Пожалуйста, введите текст');
            } else {
                setIsButtonDisabled(false)
                setError('');
            }
        }

    }

    const handleCheckClick = async () => {
        if (error) {
            return;
        }
        setResult(null);
        setShowThankYou(false);
        setIsFeedbackSent(false);
        setAgreeReport(null);
        setDisagreeFeedback('');
        setUserRating(null)
        const requestBody = api === 'url' ? { url: inputValue } : { text: inputValue };

        const response = await fetch('api/check/' + api, {

            method: 'POST', headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }, body: JSON.stringify(requestBody)
        }).then(response => {
            if (response.status === 400) {
                throw new Error('Неверные данные запроса, попробуйте снова');
            } else if (response.status === 406) {
                throw new Error('В процессе проверки возникли неполадки');
            }
            return response.json();
        })
            .then(json => {
                console.log(json)
                setMediaId(json.mediaId)
                setFeedbackId(json.checkingId);
                let totalRating = Math.min(json.totalRating, 100);
                let truthProbability = json.truthProbability;
                let newsAgendaAccuracy = json.newsAgendaAccuracy;
                let message = null;

                if (api === 'url' && (json.mediaRating === -100 || json.mediaRating === -50)) {
                    totalRating = 0;
                    truthProbability = 0;
                    newsAgendaAccuracy = 0;
                    message = json.mediaRating === -100 ? 'Система не проверяет сатирические новости' : 'Система считает новости из социальных сетей недостоверными';
                }
                setResult(<div className='check__result'>
                    {message && <div><span style={{ color: 'red', fontWeight: 'bold' }}>{message}</span></div>}
                    <h3 className='check__result-heading'>
                        Результат проверки</h3>

                    <div style={{ display: 'flex', gap: '0.5em', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '1.25em', color: "#03178C" }}>Итоговый рейтинг:</span>

                        <div style={{
                            height: '30px', width: '100%', backgroundColor: '#D9D9D9', borderRadius: '5px',
                            position: 'relative'
                        }}>
                            <div style={{
                                height: '100%',
                                width: `${totalRating}%`,
                                backgroundColor: totalRating <= 25 ? '#FF0000' : totalRating <= 75 ? '#FFA500' : '#0C9C0C',
                                borderRadius: '5px',
                                transition: 'width 0.5s',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                <span style={{
                                    float: 'right',
                                    position: 'absolute',
                                    left: '5px',
                                    top: '6px', color: 'white'
                                }}>{totalRating}%</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ marginLeft: '1.5em', display: 'flex', gap: '1em', flexDirection: 'column' }}>

                        {api === 'url' ? <span style={{
                            display: "flex",
                            alignItems: "center",
                            gap: '0.3em'
                        }}>Рейтинг источника новости: <span style={{
                            color: "#03178C",
                            fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            width: "fit-content",
                            gap: '0.3em'
                        }}> {json.mediaRating === -100 || json.mediaRating === -50 ? 0 : json.mediaRating}%
                                <a id='check-rating'
                                    style={{ width: 'fit-content' }}>
                                    <BsQuestionCircle color='gray' size={20} />
                                </a>
                            </span></span> : <></>}

                        <Tooltip style={{ maxWidth: '250px' }} anchorSelect='#check-rating'
                            place='right-end'
                            content='Оценка по данному критерию отражает уровень надежности источника,
                                  который выпустил проверяемую новость. &#10; Чем выше оценка,
                                   тем больше вероятность, что новость является правдивой.'/>


                        {api === 'url' ? <>
                            <div>Данные об источнике новости:</div>
                            <ul>
                                {json.facts && json.facts.map((el, index) => <li className='result__li' key={index}>
                                    <span>{el}</span></li>)}
                            </ul>
                        </> : <></>}

                        {<div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: '0.3em'
                        }}>
                            Схожесть с паттернами правдивых новостей:
                            <span
                                style={{
                                    color: "#03178C", fontWeight: "bold",
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: "fit-content",
                                    gap: '0.3em'
                                }}>
                                {truthProbability}%
                                <a id='check-truth' style={{ width: 'fit-content' }}>
                                    <BsQuestionCircle color='gray' size={20} />
                                </a>
                            </span>
                            <Tooltip style={{ maxWidth: '250px' }} anchorSelect='#check-truth'
                                place='right-end'
                                content='Оценка по данному критерию отражает схожесть текста новости с паттернами, которые характерны для правдивых
                                      новостей. Чем выше оценка, тем больше вероятность, что новость не является фейк-ньюс'/>
                        </div>}


                        <span style={{
                            display: "flex",
                            alignItems: "center",
                            gap: '0.3em'
                        }}>Совпадение с новостной повесткой дня (НПД): <span style={{
                            color: "#03178C", fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            width: "fit-content",
                            gap: '0.3em'
                        }}>{newsAgendaAccuracy}% <a id='check-npd' style={{ width: 'fit-content' }}><BsQuestionCircle
                            color='gray' size={20} /></a> </span></span>
                        <Tooltip style={{ maxWidth: '250px' }} anchorSelect='#check-npd'
                            place='right-end'
                            content='Новостная повестка дня – основные темы, которые обсуждаются в сми в течение дня.
                                  Чем выше процент совпадения новости с НПД, тем больше вероятность, что новость не является фейк-ньюс'/>


                        <span>Время проверки: {new Date(json.checkTime).toLocaleString()}</span>
                    </div>


                </div>);
            }).catch(err => {
                setResult(<div style={{ width: '100%', fontWeight: 'bold' }}>{err.message}</div>);
                setIsServerError(true);
            });


    }

    const renderSourceWarning = () => {
        if (selection === 'text') {
            return <p style={{ width: '100%', color: '#035AA6', fontWeight: '700', textAlign: 'justify' }}>Внимание! Проверка по тексту является менее точной, так как невозможно оценить источник новости.</p>;
        }
        return null;
    };


    return (<div>

        <div className='check-wrapper'>

            <div className='check-content-wrapper'>

                <h3 style={{ fontSize: '1.5em' }}>Фейк или не фейк? Давайте проверим!</h3>

                <select className='check__select' value={selection} onChange={handleSelectionChange}>
                    <option value="text"> Текст (менее точный способ)</option>
                    <option value="url">Ссылка</option>
                </select>
                {error && <div className='error'>{error}</div>}
                <div className='check__input-wrapper'>

                    {selection === 'text' ? <Textarea
                        className='check__text'
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={'Вставьте текст'}
                    /> : <Input value={inputValue}
                        onChange={handleInputChange}
                        placeholder={'Вставьте ссылку'} />}


                    <button className={`check__btnPink ${isButtonDisabled ? 'check__btnPink--disabled' : ''}`}
                        onClick={handleCheckClick}
                        disabled={isButtonDisabled}
                    >Проверить
                    </button>
                </div>
                {renderSourceWarning()}
                {result}
                <div className='check__result-wrapper'>
                    {!isFeedbackSent && result && !isServerError && (<>

                        <div className="check__question-wrapper">
                            <h4 style={{ fontSize: '1.45em' }}>Согласны ли вы с отчетом?</h4>
                            <div className="check__question-btn">
                                <button className='check__btnPurple' onClick={() => {
                                    setAgreeReport(true);
                                    setShowThankYou(true);
                                    sendFeedback('да', '')
                                }}>Да
                                </button>
                                <button className='check__btnPurple'
                                    onClick={() => {
                                        setAgreeReport(false);
                                        setShowThankYou(false);
                                    }}>Нет
                                </button>
                            </div>
                        </div>
                    </>)}
                    {!isFeedbackSent && agreeReport === false && (<div className="check__feedback-wrapper">
                        {mediaId !== 0 ? <>
                            <Typography component="legend">Оцените доверие к источнику новости:</Typography>
                            <div style={{ display: 'block', textAlign: 'center' }}>
                                <Rating
                                    value={userRating}
                                    onChange={(event, newValue) => {
                                        setUserRating(newValue)
                                    }}
                                    size="large"
                                />
                            </div>
                        </> : null
                        }
                        <p>Расскажите, с чем конкретно вы не согласны в отчете:</p>
                        <Textarea
                            value={disagreeFeedback}
                            onChange={event => setDisagreeFeedback(event.target.value)}
                            placeholder="Оставьте отзыв"
                        />
                        <button className='check__btnPink' type='submit'
                            onClick={() => {
                                setShowThankYou(true)
                                sendFeedback('нет', disagreeFeedback)
                            }}>Отправить отзыв
                        </button>
                    </div>)}

                    {showThankYou && (<div className="check__thank-you-wrapper">
                        <p>Спасибо за ваш отзыв! </p>
                    </div>)}
                </div>

            </div>
        </div>
    </div>

    );
};

export default Check;